<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!stockMovement">
      <span class="loader"></span>
    </span>
    <div v-if="stockMovement" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <stock-movement-view-global :stockMovement="stockMovement" />
        </tab-pane>

        <tab-pane title="details" id="2" :active="false">
          <span slot="title">
            <i class="ni ni-bullet-list-67"></i>
            {{ $t("COMMON.DETAILS") }}
          </span>
          <stock-movement-view-details
            :stockMovement="stockMovement"
            @stockMovementItemsUpdated="get"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="stockMovement" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <stock-movement-view-logs :stockMovement="stockMovement" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import {
  STATUS_DRAFT,
  STATUS_CANCELED,
  STATUS_VALIDATED,
} from "@/constants/stockMovements";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import StockMovementViewGlobal from "../partials/StockMovementViewGlobal.vue";
import StockMovementViewDetails from "../partials/StockMovementViewDetails.vue";
import StockMovementViewLogs from "../partials/StockMovementViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    StockMovementViewGlobal,
    StockMovementViewDetails,
    StockMovementViewLogs,
    ListFileComponent,
  },

  mixins: [requestErrorMixin],

  props: {
    stockMovementId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stockMovement: null,
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_VALIDATED: STATUS_VALIDATED,
      STATUS_CANCELED: STATUS_CANCELED,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("stockMovements/get", this.stockMovementId);
        this.stockMovement =
          this.$store.getters["stockMovements/stockMovement"];
        this.$emit("stockMovementLoaded", this.stockMovement);
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewPurchasesOrder() {
      this.$router.push(
        this.$objectViewRoute(this.stockMovement.purchasesOrder)
      );
    },
  },
};
</script>
